<template>
  <div id="brokerToBrokerTransfer">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit" v-if="formFlag">
          <h4>{{ $t('deposit.b2b.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/broker.png" alt />
              <p>{{ $t('deposit.b2b.option', { platform: $platform.info.fullName }) }}</p>
              <ul>
                <li>
                  <i18n path="deposit.b2b.inst1" tag="div">
                    <template v-slot:download>
                      <a :href="host">{{ $t('deposit.b2b.b2bForm') }}</a>
                    </template>
                  </i18n>
                </li>
                <li>{{ $t('deposit.b2b.inst2') }}</li>
                <li>{{ $t('deposit.b2b.inst3') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.b2b.header') }}</p>
              <el-form label-position="top" :model="brokerForm" ref="brokerForm" status-icon :rules="brokerRules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        :supportedCurrenciesList="supportedCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="brokerForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('deposit.b2b.b2bForm')" prop="uploadFile">
                        <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="brokerForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <div class="info">
                  <i18n path="deposit.default.alt" tag="p">
                    <template v-slot:mailTo>
                      <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                    </template>
                  </i18n>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
        <Result v-if="successFlag">
          {{ $t('deposit.default.reminderFundAppear') }}
        </Result>
      </div>
    </div>
  </div>
  <!-- Broker to Broker Transfer 结束 -->
</template>

<script>
import NumericInput from '@/components/NumericInput';
import vUpload from '@/components/vUpload';
import AccountNumber from '@/components/form/AccountNumber';
import Result from '@/components/Result';
import mixin from '@/mixins/page/deposit';
import { apiBroker_to_broker_payment } from '@/resource';

export default {
  name: 'BrokerToBrokerTransfer',
  components: { NumericInput, vUpload, AccountNumber, Result },
  mixins: [mixin],
  data() {
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) callback(new Error(this.$t('deposit.b2b.formValidation.upload')));
      else callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      brokerForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      brokerRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            message: this.$t('deposit.b2b.formValidation.upload'),
            trigger: 'change'
          }
        ]
      },
      minLimit: this.$platform.depositOneAmountMin,
      host: '',
      fileList: [],
      formFlag: true,
      successFlag: false,
      supportedCurrencies: ['USD', 'AUD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD']
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.brokerForm.accountNumber = accountNumber;
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['brokerForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['brokerForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiBroker_to_broker_payment(
        {
          //payment channel is 7, payment type is 2
          mt4Account: this.brokerForm.accountNumber,
          operateAmount: this.brokerForm.amount,
          applicationNotes: this.brokerForm.notes,
          currency: '',
          fileList: this.fileList
        },
        this.token
      );
    }
  },
  mounted() {
    let filePath = '/files/broker-to-broker-form';
    if (this.regulator === 'vfsc') {
      filePath = '/files/pdf/guides/Vantage-FX-Broker-to-Broker-Transfer-Form-Commbank.pdf';
    }
    this.host = location.protocol.concat('//').concat(this.GLOBAL_DOMAIN_WEBSITE + filePath);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
